<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                                            <b-row>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="Farmers NID" vid="farmers_nid">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="farmers_nid"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.fiscal_year')}}
                                                        </template>
                                                            <b-form-input
                                                            id="farmers_nid"
                                                            v-model="demandCollection.fiscal_year"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            disabled
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <template>
                                                        <div style="font-size:18px; background-color: #66CC99;" class="mb-3">
                                                            <h5 class="text-white"> {{ $t('bsri_demandDIS.mill_information') }}</h5>
                                                        </div>
                                                    </template>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <b-table-simple class="table table-bordered" responsive>
                                                        <thead>
                                                            <tr>
                                                                <th>{{ $t('globalTrans.sl_no') }}</th>
                                                                <th>{{ $t('bsri_demandDIS.mill_name') }}</th>
                                                                <th>{{ $t('bsri_demandDIS.total_farmer') }}</th>
                                                                <th>{{ $t('bsri_demandDIS.total_subsidized_land') }}</th>
                                                                <th>{{ $t('bsri_demandDIS.proposed_amount') }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(item, index) in millDetails" :key="index">
                                                                <td>{{ $n(index + 1) }}</td>
                                                                <td>{{ $i18n.locale === 'bn' ? item.mill_name_bn : item.mill_name }}</td>
                                                                <td>{{ $n(item.total_farmers) }}</td>
                                                                <td>{{ $n(item.total_lands) }}</td>
                                                                <td>{{ $n(item.total_lands) }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </b-table-simple>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <ValidationProvider name="Comments (En)" vid="comments" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="2"
                                                            label-for="comments"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.comments_en')}}
                                                        </template>
                                                            <b-form-textarea
                                                            id="description"
                                                            v-model="demandCollection.comments_en"
                                                            rows="1"
                                                            max-rows="2"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <ValidationProvider name="Comments (Bn)" vid="comments_bn" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="2"
                                                            label-for="comments_bn"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.comments_bn')}}
                                                        </template>
                                                            <b-form-textarea
                                                            id="description"
                                                            v-model="demandCollection.comments_bn"
                                                            rows="1"
                                                            max-rows="2"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <div class="col text-right">
                                                        <b-button @click="approvalButton()" type="submit" variant="success" class="mr-2">{{ $t('globalTrans.approve') }}</b-button>
                                                        &nbsp;
                                                        <b-button @click="rejectButton()" type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.reject') }}</b-button>
                                                        &nbsp;
                                                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { subSidyProApprDetails, subSidyProApprStore } from '../../api/routes'

export default {
    props: ['id', 'fiscalYearId'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            demandCollection: {
                comments_en: '',
                comments_bn: '',
                fiscal_year_id: this.fiscalYearId,
                status: 0
            },
            millDetails: []
        }
    },
    created () {
        if (this.fiscalYearId) {
            this.getEditingData()
            this.loadData()
        }
    },
    watch: {
    },
    mounted () {
        core.index()
    },
    computed: {
    },
    methods: {
        approvalButton () {
            this.formData.status = 2
        },
        rejectButton () {
            this.formData.status = 3
        },
        getEditingData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            const data = JSON.parse(JSON.stringify(Object.assign({}, tmpData)))
            this.demandCollection.fiscal_year = this.$i18n.locale === 'bn' ? data.fiscal_year_bn : data.fiscal_year_en
        },
        async saveData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            result = await RestApi.postData(incentiveGrantServiceBaseUrl, subSidyProApprStore, this.demandCollection)
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            this.loading = false
            if (result.success) {
                this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })
                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        },
        loadData () {
            RestApi.getData(incentiveGrantServiceBaseUrl, `${subSidyProApprDetails}/${this.fiscalYearId}`).then(response => {
                if (response.success) {
                    this.millDetails = this.getRelatinalData(response.data)
                }
            })
        },
        getRelatinalData (data) {
            const millList = this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1)
            const listData = data.map(item => {
                const millInfoObj = millList.find(MillItem => MillItem.value === item.mill_info_id)
                const millInfoData = { mill_name: millInfoObj.text_en, mill_name_bn: millInfoObj.text_bn }
                return Object.assign({}, item, millInfoData)
            })
            return listData
        }
    }
}
</script>
